import React, {useState, useEffect} from 'react';
import styles from "../../components/Investor/newsList.module.css"
import Link from "gatsby-link"
import Layout from "../../components/Investor/layout";
import SEO from "../../components/seo"
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import News_styles from "../../components/home2news.module.css"
import { graphql } from 'gatsby';
import {isNullUndefined, getMomentDate, capitalizeFirstLetter} from "../../util"
import queryString from 'query-string';
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryNewsItemTemplate = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulNewsListTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
          spaceId
          slug
          title
        }
        allContentfulNewsItemTemplate {
          edges {
            node {
            author
            datePublished
            description {
              content {
                content {
                  value
                }
              }
            }
            slug
            title
            year
          }
          }
        }
    }
`

const NewsList = (props) => {

  const {edges} = props.data.allContentfulNewsItemTemplate;

  if(isNullUndefined(edges)){
    edges = []
  }

  const sortByLatestYear = (a, b) => {
    if(parseInt(a) > parseInt(b)){
      return -1;
    }
    if(parseInt(a) < parseInt(b)){
      return 1
    }
    return 0;
  }
  const sortByDatePublished = (a, b) => {
    let datePublished1 = (getMomentDate(a.node.datePublished)).valueOf();
    let datePublished2 = (getMomentDate(b.node.datePublished)).valueOf();
    return datePublished2 - datePublished1
  }

  const tabsAsYears = (() => {
    let years = [];
    for(let edge of edges){
      if(!years.includes(edge.node.year)){
        years.push(edge.node.year);
      }
    }
    return years.sort(sortByLatestYear);
  })()

  const [selectedYear, selectYear] = useState(tabsAsYears[0]);

  useEffect(() => {
    let {activeYear, veevaTab} = queryString.parse(props.location.search || "")

    if(!isNullUndefined(activeYear)){
      selectYear(`${activeYear}`);
    }

    if(veevaTab){
      selectYear(`${veevaTab}`)
    }
  }, [])

  return ( 
    <TemplateWrapper>
      <Layout>
    <SEO title={`Latest News | Investor or Media Relations`} />
           <section className={styles.details_card}>
       <div className="container">
           <div className={"row"}>
               <div className={"col-12"}>
               <h1 style={{marginBottom: '20px'}}>Our Latest News</h1>
               </div>
           </div>
           <div className="row">
               <div className="col-12">
    <Tabs defaultTab={isNullUndefined(selectedYear) ? `${tabsAsYears[0]}` : selectedYear} onChange={(year) => selectYear(year)}>
   <TabList>
    {tabsAsYears.map((item, index) => <Tab key={index} tabFor={item}>{item}</Tab>)}
   </TabList>
   <div className="tabs_content">
    <div className="row">
     {edges.filter(item => item.node.year === selectedYear).sort(sortByDatePublished).map((item, index) => {
      let tabId = item?.node?.year;
      let child = item?.node;
      let datePublished = getMomentDate(child?.datePublished);
      let description = child?.description?.content || "";
         
       console.log("description", description)

       return (
         <Link className={`${News_styles.news_card} ${News_styles.card} col-12 col-sm-12 col-md-6 col-lg-4`} key={index} to={`/Investor/news/${item.node.slug}`} state={{prevPath: props.location.pathname, activeYear: tabId}}>
           <TabPanel tabId={tabId}>
            <div key={item?.node?.slug} className={``} >
            <div className={News_styles.wrapper}>
               <div className={News_styles.date}>
                <span className={News_styles.day}>{datePublished?.format("DD")}</span>
                <span className={News_styles.month}>{datePublished?.format("MMM")}</span>
                <span className={News_styles.year}>{datePublished?.format("YYYY")}</span>
              </div>
              <div className={News_styles.data}>
                <div className={News_styles.content}>
                
                <h1 className={News_styles.title}>
                  {child?.title}
                </h1>
                <p className={News_styles.text}>
                  {description && description?.reduce((acc, item) => {
                      let contentDescription = item.content || [];
                      for(let text of item.content){
                          acc += `${text.value} `;
                      }
                      return acc
                  },"")}
                </p>
                <label htmlFor="show-menu2" className={News_styles.menu_button}><span></span></label>
              </div>
                
              </div>
            </div>
          </div>
           </TabPanel>
         </Link>
       )
     })}
     </div>
   </div>
   </Tabs>


               </div>
           </div>
         
       </div>
   </section>
    </Layout>
    </TemplateWrapper>
    );
}
 
export default NewsList;
