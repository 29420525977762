import { navigate } from "gatsby";
import React from "react"
import { ValidateRoute, ShowDisclaimer } from '../util/route-helper';
import { 
    getUkResidentsHCPLandingDisclaimerPopupConfig, 
    getNonUkResidentsHCPLandingDisclaimerPopupConfig,
    getNonUkResidentsNonHcpGermanPatientsDisclaimerPopupConfigs,
    getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigs, 
    getDefaultMainLandingDisclaimerPopupConfigsInvestors,
    getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody,
    getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigsEfmody,
    getUsaOnlyInvestorDisclaimerPopupConfigsInvestors,
    getNonUkClinicalTrialsDisclaimerDirectAccessPopupConfigs
} from "../util/configs"

import UkResidentsLandingDisclaimer from "../components/prompts/disclaimers/ukResidents/user-selection-disclaimer"

class TemplateWrapper extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            shouldRender: false,
            directLinkPopUpConfig: null
        }
    }

    componentDidMount(){
        let shouldRender = ValidateRoute();
        this.setState({shouldRender});
        this.setUpDisclaimer();
    }
   

    setUpDisclaimer(){
        let activeEnvironment = "staging";
        console.log("Setting up disclaimer....", activeEnvironment);
        let okCallback = async () => await navigate("/")
        let closeCallback = async () => { }

      
        if (window.location.href.toLowerCase().includes("/ukresidents/hcp")){
            ShowDisclaimer("ukresidents-hcp", getUkResidentsHCPLandingDisclaimerPopupConfig(null, true), this);
            localStorage.setItem("ukresidents-hcp", JSON.stringify({ disclaimerShown: true }));
        }
        else if (window.location.href.toLowerCase().includes("/ukresidents/non-hcp/information-for-uk-patients-on-efmody") || window.location.href.toLowerCase().includes("/ukresidents/non-hcp/information%20for%20uk%20patients%20on%20efmody%c2%ae")){
            ShowDisclaimer("ukresidents-nonhcp-info-for-uk-patients-on-efmody", getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigsEfmody(okCallback, closeCallback,true), this);
            localStorage.setItem("ukresidents-nonhcp-info-for-uk-patients-on-efmody", JSON.stringify({ disclaimerShown: true }));
        }
        else if (window.location.href.toLowerCase().includes("/ukresidents/non-hcp/information-for-uk-patients")){
            ShowDisclaimer("ukresidents-nonhcp-info-for-uk-patients", getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigs(okCallback, closeCallback,true), this);
            localStorage.setItem("ukresidents-nonhcp-info-for-uk-patients", JSON.stringify({ disclaimerShown: true }));
        }else if (window.location.href.toLowerCase().includes("/investor/usa-only-investor-information")){
            ShowDisclaimer("investor-usa-only", getUsaOnlyInvestorDisclaimerPopupConfigsInvestors(null, true), this);
            localStorage.setItem("investor-usa-only", JSON.stringify({ disclaimerShown: true })); 
        }

        else if (window.location.href.toLowerCase().includes("/investor")) {
            ShowDisclaimer("investor-home", getDefaultMainLandingDisclaimerPopupConfigsInvestors(null, true), this);
            localStorage.setItem("investor-home", JSON.stringify({ disclaimerShown: true }));
        }
        else if (activeEnvironment && window.location.href.toLocaleLowerCase().includes("/investor/dallas")) {
            localStorage.removeItem("investor-home");
        }

        else if(window.location.href.toLowerCase().includes("/nonukresidents/hcp/clinical-trials")){
            ShowDisclaimer("nonukresidents-hcp-clinicaltrials", getNonUkClinicalTrialsDisclaimerDirectAccessPopupConfigs(null, true), this);
            localStorage.setItem("nonukresidents-hcp-clinicaltrials", JSON.stringify({ disclaimerShown: true }));
        }

        else if (window.location.href.toLowerCase().includes("/nonukresidents/hcp") && !window.location.href.toLowerCase().includes("/nonukresidents/hcp/information-for-german-prescribers?dc")) {
            ShowDisclaimer("nonukresidents-hcp", getNonUkResidentsHCPLandingDisclaimerPopupConfig(null, true), this);
            localStorage.setItem("nonukresidents-hcp", JSON.stringify({ disclaimerShown: true }));
        }
        
        else if(window.location.href.toLowerCase().includes("/nonukresidents/non-hcp/clinical-trials")){
            ShowDisclaimer("nonukresidents-nonhcp-clinicaltrials", getNonUkClinicalTrialsDisclaimerDirectAccessPopupConfigs(null, true), this);
            localStorage.setItem("nonukresidents-nonhcp-clinicaltrials", JSON.stringify({ disclaimerShown: true }));
        }
        
        else if (window.location.href.toLowerCase().includes("/nonukresidents/non-hcp/information-for-german-patients-on-efmody")){
            ShowDisclaimer("nonukresidents-nonhcp-info-for-german-patients-on-efmody", getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody(okCallback, closeCallback, true), this);
            localStorage.setItem("nonukresidents-nonhcp-info-for-german-patients-on-efmody", JSON.stringify({ disclaimerShown: true }));
        }else if (window.location.href.toLowerCase().includes("/nonukresidents/non-hcp/information-for-german-patients")) {
            ShowDisclaimer("nonukresidents-nonhcp-info-for-german-patients", getNonUkResidentsNonHcpGermanPatientsDisclaimerPopupConfigs(okCallback, closeCallback, true), this);
            localStorage.setItem("nonukresidents-nonhcp-info-for-german-patients", JSON.stringify({ disclaimerShown: true }));
        }
    }
    render(){
        const { shouldRender, directLinkPopUpConfig} = this.state;

        return (
            <React.Fragment>
                {this.props.children}
                {directLinkPopUpConfig && directLinkPopUpConfig.show && <UkResidentsLandingDisclaimer popupConfig={directLinkPopUpConfig} hide={() => this.setState({ directLinkPopUpConfig: null })} />}

            </React.Fragment>
        )
    }
}

export default TemplateWrapper;