import { config } from "./configs"
import { Link, navigate } from "gatsby"
import queryString from 'query-string';
import {useLocation} from "@reach/router"

export function ValidateRoute(){
    if (process.env.GATSBY_ACTIVE_ENV !== 'production')  return true;
    
    var nonUkDomain = config.nonUkResidentsBaseUrl.split("/")[config.nonUkResidentsBaseUrl.split("/").length - 1]

    var isUkResidentsUrl = window.location.href.split("/").findIndex(x => x.toLocaleLowerCase() === 'ukresidents') >= 0;

    if (isUkResidentsUrl){
        var ukDomain = config.ukResidentsBaseUrl.split("/")[config.ukResidentsBaseUrl.split("/").length -1];
        if (window.location.href.toLowerCase().indexOf(ukDomain.toLowerCase()) === -1){
            navigate("/404");
            return false;
        }
    }else{
        var isInvestorUrl = window.location.href.split("/").findIndex(x => x.toLocaleLowerCase() === 'investor') >= 0;
        var isNonUkUrl = window.location.href.split("/").findIndex(x => x.toLocaleLowerCase() === 'nonukresidents') >= 0;

        if (window.location.href.toLowerCase().indexOf(nonUkDomain.toLowerCase()) === -1 && (isInvestorUrl || isNonUkUrl) ) {
            navigate("/404");
            return false;
        }
    }
    
    return true;
    
}

export function ShowDisclaimer(pathName,directLinkPopUpConfig, context){

    if (process.env.GATSBY_ACTIVE_ENV !== "production") {
        return;
    }
    if (window.location.href.toLocaleLowerCase().includes("/investor/dallas")) {
        return;
    }
    

    const location = window.location//useLocation();

    let {veevaHideDisclaimers} = queryString.parse(location.search || "");

    if(veevaHideDisclaimers){
        return;
    }
    
    var data = JSON.parse(localStorage.getItem(pathName));
    if (data && data.disclaimerShown){
        return;
    }
    
    context.setState({ directLinkPopUpConfig})
}
export function ShowDisclaimerForDynamicPages(pathName, directLinkPopUpConfig, setState) {
    
    if(process.env.GATSBY_ACTIVE_ENV !== "production" && !window.location.href.includes("dallas")){
        return;
    }

    const location = window.location//useLocation();
    
    let {veevaHideDisclaimers} = queryString.parse(location.search || "");

    if(veevaHideDisclaimers){
        return;
    }
    
    var data = JSON.parse(localStorage.getItem(pathName));
    if (data && data.disclaimerShown) {
        return;
    }

    setState({ directLinkPopUpConfig })
}